import { useEffect, useState } from "react";
import styles from "./Assets/SocialIcon.module.css";

interface IconStylesT {
    width: string,
    height: string,
    padding: string,
    margin: string,
}

interface SocialT {
    url?: string,
    nome?: string,
    width?: string | number,
    height?: string | number,
    padding?: string | number,
    margin?: string | number,
    header?: ImagemT,
    footer?: ImagemT,
    type?: "header" | "footer",
}

interface ImagemT {
    large: string,
    medium: string,
    thumb: string
}

const SocialIcon = (props: SocialT) => {
    const [customStyles, setStyles] = useState<IconStylesT>();

    useEffect(() => {
        let style: IconStylesT = {
            width: typeof props.width === "string" ? props.width : props.width ? `${props.width}px` : "48px",
            height: typeof props.height === "string" ? props.height : props.height ? `${props.height}px` : "48px",
            padding: typeof props.padding === "string" ? props.padding : props.padding ? `${props.padding}px` : "10px",
            margin: typeof props.margin === "string" ? props.margin : props.margin ? `${props.margin}px` : "0px",
        }
        setStyles(style);
    }, [props]);

    return (
        <a href={props.url} title={props.nome} rel="nooepener noreferrer" target="_blank" className={styles.button}>
            <img src={props.type === "header" ? props.header?.large : props.footer?.large} alt={props.nome} style={{ ...customStyles }} />
        </a>
    )
}

export default SocialIcon;

export type { SocialT };