import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import APICall from "../../APIConnect";
import { MetasT } from "../SEOHelper/interfaces";

interface ResumoT {
    configuracoes: ConfiguracoesT
}

interface ConfiguracoesT {
    app_title?: string,
    twitter?: string
}

const Metas = (props: MetasT) => {
    const [resumo, setResumo] = useState<ResumoT>();

    useEffect(() => {
        APICall("GET", `/configuracoes`).then(response => {
            if (parseInt(response.data.status) !== 200) {
                window.alert("Ocorreu um erro ao carregar os dados do site.");
            } else {
                setResumo(response.data.success);
            }
        });
    }, [props]);

    return (
        <>
            {resumo &&
                <Helmet>
                    {props &&
                        <>
                            {(resumo.configuracoes || props.title) && <title>{props.title ? props.title : resumo.configuracoes.app_title}</title>}
                            {props.description && <meta name="description" content={props.description}></meta>}
                            {props.imagem && <meta name="image" content={`${props.imagem?.generic}.jpg?size=1200`}></meta>}
                            {props.keywords && <meta name="keywords" content={props.keywords}></meta>}
                            {props.title && <meta property="og:title" content={props.title}></meta>}
                            {props.description && <meta property="og:description" content={props.description}></meta>}
                            {props.imagem && <meta property="og:image" content={`${props.imagem?.generic}.jpg?size=1200`}></meta>}
                            {props.imagem && <meta property="og:image:width" content={props.width ? props.width : "1200"}></meta>}
                            {props.imagem && <meta property="og:image:height" content={props.height ? props.height : "630"}></meta>}
                            {window && <meta property="og:url" content={window.location.href}></meta>}
                            {resumo.configuracoes && <meta property="og:site_name" content={resumo.configuracoes.app_title}></meta>}
                            {props && <meta property="og:type" content={props.type ? props.type : "website"}></meta>}
                            {props && <meta name="twitter:card" content={props.card ? props.card : "summary"}></meta>}
                            {resumo.configuracoes && <meta name="twitter:site" content={resumo.configuracoes.twitter}></meta>}
                            {props.title && <meta name="twitter:title" content={props.title}></meta>}
                            {props.description && <meta name="twitter:description" content={props.description}></meta>}
                            {props.imagem && <meta name="twitter:image" content={`${props.imagem?.generic}.jpg?size=1200`}></meta>}
                        </>
                    }
                </Helmet>
            }
        </>
    )
}

export default Metas;