import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { callCacheGenerate, callForMetaUpdate, setDefaultMetas } from "./functions";
import { MetasT } from './interfaces';
import APICall from '../../APIConnect';

interface SEOHelperT {
    withCache?: boolean
}

function SEOHelper(props: SEOHelperT) {
    const location = useLocation();
    const [metas, setMetas] = useState<MetasT>();

    useEffect(() => {
        getMetas(window.location.pathname.replaceAll("/", "-"));
        if (props.withCache) {
            var ssrTimeout = setTimeout(() => callCacheGenerate(), 5000);

            return () => {
                clearTimeout(ssrTimeout);
            }
        }
    }, [location]);

    useEffect(() => {
        let mts = metas && metas.title ? metas : window.defaultMetas;
        callForMetaUpdate(mts);
    }, [metas]);

    useEffect(() => {
        setDefaultMetas();
    }, []);

    const getMetas = (slug: string) => {
        let ok = false;
        APICall("GET", `/v2/seo/${slug}`).then(response => {
            if (response.data.status === 200) {
                let info: MetasT = response.data.success;
                let mts: MetasT = {
                    "app_title": info.app_title ? info.app_title : window.defaultMetas.app_title,
                    "title": info.title ? info.title : window.defaultMetas.title,
                    "description": info.description ? info.description : window.defaultMetas.description,
                    "imagem": info.imagem && info.imagem ? info.imagem : window.defaultMetas.imagem,
                    "keywords": info.keywords ? info.keywords : window.defaultMetas.keywords,
                    "width": info.width ? info.width : window.defaultMetas.width,
                    "height": info.height ? info.height : window.defaultMetas.height,
                    "type": info.type ? info.type : window.defaultMetas.type,
                    "card": info.summary ? info.summary : window.defaultMetas.summary,
                    "twitter": ""
                }
                ok = true;
                setMetas(mts);
            }
        });

        if (!ok) {
            callForMetaUpdate(window.defaultMetas);
        }
    }

    return (null);
}

export default SEOHelper;