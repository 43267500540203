import { Link } from "react-router-dom";
import styles from "./Submenu.module.css";
import { useEffect, useState } from "react";
import { uniqueId } from "lodash";

interface ItemT {
    nome: string,
    url: string,
    imagem: string
}

interface SubmenuT {
    itens: Array<ItemT>
}

const Submenu = (props: SubmenuT) => {
    const [imageBlock, setImageBlock] = useState<ItemT>();
    const [transition, setTransition] = useState<string>();

    useEffect(() => {
        setImageBlock(props.itens[0]);
    }, []);

    const handleBlockChange = (item: ItemT) => {
        if (imageBlock && item.imagem !== imageBlock.imagem) {
            setTransition(imageBlock.imagem);
        }
        setImageBlock(item);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.grid}>
                <div className={styles.menu}>
                    {props.itens.map((item) => {
                        return (
                            <Link key={uniqueId("menu_categoria")} to={item.url} title={item.nome} onMouseOver={() => handleBlockChange(item)} style={{ fontWeight: item.nome === imageBlock?.nome ? 600 : 300 }}>{item.nome}</Link>
                        );
                    })}
                </div>
                {imageBlock ?
                    <div className={styles.imagem} style={{ backgroundImage: `url("${imageBlock.imagem}")` }}>
                        {transition ?
                            <div key={uniqueId(transition)} className={styles.transition} style={{ backgroundImage: `url("${transition}")` }}></div>
                            : <></>}
                        <div className={styles.textos}>
                            <h3>{imageBlock.nome}</h3>
                            <Link to={imageBlock.url} title={imageBlock.nome}>Ver produtos</Link>
                        </div>
                    </div>
                    : <></>}
            </div>
        </div>
    );
}

export default Submenu;
export type { ItemT };