import styles from "./Header.module.css";
import En from "./Assets/en.svg";
import Es from "./Assets/es.svg";
import Pt from "./Assets/pt.svg";
import Logo from "./Assets/logo.svg";
import Pesquisa from "./Assets/pesquisa.svg";
import Cart from "./Assets/cart.svg";
import Menu from "./Assets/menu.svg";
import SocialIcon, { SocialT } from "../../Components/DefaultPack/SocialIcon";
import { Link, useNavigate } from "react-router-dom";
import Submenu, { ItemT } from "../../Components/Submenu";

import { ReactElement, useContext, useEffect, useState } from "react";
import Context from "../../Context";
import { CategoriaT, LinhaT } from "../../Components/Produto/interfaces";
import { uniqueId } from "lodash";

const Header = () => {
    const navigate = useNavigate();
    const { linhas, categorias, textos, usuario, posts, redesSociais } = useContext(Context);
    const [menu, setMenu] = useState<any>(false);
    const [openMenu, setOpenMenu] = useState<any>(false);
    const [search, toggleSearch] = useState<boolean>(false);

    useEffect(() => {
        let menu: Array<ReactElement> = [];
        if (linhas) {
            linhas.forEach((linha: LinhaT) => {
                let submenu: Array<ItemT> = [];
                if (linha.categorias) {
                    linha.categorias.forEach((categoria: CategoriaT) => {
                        submenu.push({
                            imagem: categoria.imagem.large ? categoria.imagem.large : "",
                            nome: categoria.nome,
                            url: `/produtos?linha=${linha.codigo}&categoria=${categoria.slug}`
                        });
                    });
                }
                menu.push(
                    <div key={uniqueId("menu_item_")}>
                        <Link to={`/produtos?linha=${linha.codigo}`} title={linha.nome}>{linha.nome}</Link>
                        <div className={styles.submenu}>
                            <Submenu
                                itens={submenu}
                            />
                        </div>
                    </div>
                );
            });

            setMenu(menu);
        }
    }, [linhas, categorias]);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target instanceof Element) {
            if (event.target.tagName === "A") {
                setOpenMenu(false);
            }
        }
    }

    const handleBusca = (e: React.KeyboardEvent<HTMLInputElement> | undefined) => {
        if (e?.key === "Enter") {
            let pesquisa: HTMLInputElement | null = document.getElementById("search_input_header") as HTMLInputElement | null;
            if (pesquisa) {
                toggleSearch(false);
                return navigate(`/produtos?pesquisa=${pesquisa.value}`);
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.topbar}>
                <div className={styles.container}>
                    <div className={styles.split}>
                        <div className={styles.language}>
                            <a href={`/pt/`} title="Português">
                                <img src={Pt} alt="Português" />
                            </a>
                            <a href={`/en/`} title="Inglês">
                                <img src={En} alt="Inglês" />
                            </a>
                            <a href={`/es/`} title="Espanhol">
                                <img src={Es} alt="Espanhol" />
                            </a>
                        </div>
                        <div className={styles.socialWrapper}>
                            <div className={styles.social}>
                                <p>Associado: </p>
                                <div className={styles.bgAbimad}>
                                    <img width={100} src="https://www.2aceramica.com.br/sistema/public_files/photos/shared/abimad37.png" alt="ABIMAD" />
                                </div>
                                <img width={100} src="https://www.2aceramica.com.br/sistema/public_files/photos/shared/logo-abcasa-HORIZONTAL2-02-scaled.jpg" alt="ABCASA" />
                            </div>
                            <div className={styles.social}>
                                <p>Acompanhe nossas mídias!</p>
                                {redesSociais && redesSociais.length > 0 ? redesSociais.map((social: SocialT) => {
                                    return <SocialIcon key={uniqueId("social_icon_")} {...social} type="header" width={20} height={20} padding={"0px"} />
                                }) : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.split}>
                        <div className={styles.left}>
                            <Link to="/"><img src={Logo} alt="2A Cerâmica" /></Link>
                            <div className={`${styles.menu} ${openMenu ? styles.active : ''}`} onClick={(e) => handleMenuClick(e)}>
                                <Link to="/" translate="no">Home</Link>
                                <Link to="/empresa">Empresa</Link>
                                <Link to="/produtos">Produtos</Link>
                                <a href={`${textos.catalogo}`} target="_blank" rel="noopener noreferrer" className={styles.menuMob}>Catálogo</a>
                                {posts && posts.length > 0 ? <Link to="/blog" translate="no">Blog</Link> : <></>}
                                <Link to="/contato">Contato</Link>
                                <span className={styles.menuMob} onClick={() => toggleSearch(true)}>Pesquisa</span>
                                {usuario ? <Link className={styles.menuMob} to="/carrinho">Carrinho</Link> : <></>}
                                {!usuario ? <Link className={styles.menuMob} to="/minha-conta">Login / Cadastro</Link> : <></>}
                                {usuario ? <Link className={styles.menuMob} to="/minha-conta/2FA">Minha conta</Link> : <></>}
                            </div>
                            <div className={styles.overlay} onClick={() => { setOpenMenu(false) }}></div>
                        </div>
                        <button type="button" onClick={() => setOpenMenu(true)} className={styles.hamburguer}>
                            <img src={Menu} alt="Menu" />
                        </button>
                        <div className={styles.right}>
                            <button type="button" className={styles.pesquisa} onClick={() => toggleSearch(!search)}>
                                <img src={Pesquisa} alt="Pesquisar" />
                            </button>
                            <a href={`${textos.catalogo}`} target="_blank" rel="noopener noreferrer" className={styles.catalogo}>Baixar catálogo</a>
                            {usuario ? <Link to="/carrinho" title="Meu carrinho" className={styles.pesquisa}><img src={Cart} alt="Pesquisar" /></Link> : <></>}
                            <Link to={usuario ? "/minha-conta/2FA" : "/minha-conta"} title="Minha conta" className={styles.conta}>
                                <img src={`https://www.gravatar.com/avatar/${usuario ? usuario.imagem : 0}?d=mp`} alt="Usuário" />
                                <div>
                                    <span>Olá, seja bem-vindo(a)!</span>
                                    <p>{usuario ? usuario.nome : "Login / Cadastro"}</p>
                                </div>
                            </Link>
                        </div>
                        <div className={`${styles.pesquisa_overlay} ${search ? styles.active : ""}`}>
                            <div className={styles.disableSearch} onClick={() => toggleSearch(false)}></div>
                            <div className={styles.pesquisa_box}>
                                <input type="text" name="pesquisa" placeholder="Busque seu produto" id={`search_input_header`} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleBusca(e)} />
                                <img src={Pesquisa} alt="Buscar" onClick={() => handleBusca(undefined)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {menu ?
                <div className={styles.container}>
                    <div className={styles.linhas}>
                        {menu}
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}

export default Header;