import React from 'react';

interface ContextT {
    textos?: any,
    imagens?: any,
    banners?: any,
    linhas?: any,
    categorias?: any,
    produtos?: any,
    lancamentos?: any,
    configuracoes?: any,
    usuario?: any,
    carrinho?: any,
    posts?: any,
    redesSociais?: any,
    setCarrinho?: any
}

const Context = React.createContext<ContextT>({});

export default Context;