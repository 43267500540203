import { useContext } from "react";
import Context from "../../../Context";
import Icon from "./whatsapp.svg";

import styles from "./FloatWhatsapp.module.css";

const FloatWhatsapp = () => {
    const { configuracoes } = useContext(Context);
    return (
        <a className={styles.link} href={`https://wa.me/${configuracoes.whatsapp.replace(/\D/g, '')}`} title="WhatsApp" target="_blank" rel="noopener noreferrer">
            <img src={Icon} className={styles.icon} alt="Whatsapp" />
        </a>
    );
}

export default FloatWhatsapp;