import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Suspense, lazy, useContext, useEffect, useState } from 'react';

import "./index.css";

import Context from './Context';

import ScrollToTop from "./Helpers/ScrollToTop";
import Metas from "./Helpers/Metas";
import SEOHelper from "./Helpers/SEOHelper";
import APICall from "./APIConnect";
import Cookies from "./Components/DefaultPack/Cookies";
import GTManager from "./Components/DefaultPack/GTManager";
import MultiLanguage, { getLangFromUrl } from "./Components/DefaultPack/MultiLanguage";
import Header from "./Parts/Header";
import Footer from "./Parts/Footer";
import { CarrinhoT } from "./Pages/Carrinho";
import Schema from "./Components/DefaultPack/Schema";
import FloatWhatsapp from "./Components/DefaultPack/FloatWhatsapp";

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Obrigado = lazy(() => import('./Pages/Obrigado'));
const Blog = lazy(() => import('./Pages/Blog'));
const Generic = lazy(() => import('./Pages/Generic'));
const Contato = lazy(() => import('./Pages/Contato'));
const Post = lazy(() => import('./Pages/Post'));
const Downloads = lazy(() => import('./Pages/Downloads'));
const Login = lazy(() => import('./Pages/Login'));
const TFA = lazy(() => import('./Pages/TFA'));
const Senha = lazy(() => import('./Pages/Senha'));
const Carrinho = lazy(() => import('./Pages/Carrinho'));

const router = createBrowserRouter([
  {
    path: "*",
    element: <Main />,
  },
],
  {
    basename: getLangFromUrl()
  });

function App() {
  const [textos, setTextos] = useState<any>();
  const [imagens, setImagens] = useState<any>();
  const [banners, setBanners] = useState<any>();
  const [usuario, setUsuario] = useState<any>();
  const [configuracoes, setConfiguracoes] = useState<any>();
  const [linhas, setLinhas] = useState<any>();
  const [produtos, setProdutos] = useState<any>();
  const [lancamentos, setLancamentos] = useState<any>();
  const [categorias, setCategorias] = useState<any>();
  const [posts, setPosts] = useState<any>();
  const [redesSociais, setRedesSociais] = useState<any>();
  const [carrinho, setCarrinho] = useState<any>();
  const value = { carrinho, setCarrinho, redesSociais, posts, textos, usuario, imagens, banners, configuracoes, linhas, categorias, produtos, lancamentos };

  useEffect(() => {
    APICall("GET", "/configuracoes").then(response => {
      if (response.data.status !== 200) {
        window.alert("Ocorreu um erro ao solicitar os dados do site.");
      } else {
        let configuracoes: any | undefined = response.data.success?.configuracoes;
        setConfiguracoes(configuracoes);

        let banners: any | undefined = response.data.success?.banners;
        setBanners(banners);

        let imagens: any | undefined = response.data.success?.imagens;
        setImagens(imagens);

        let textos: any | undefined = response.data.success?.textos;
        setTextos(textos);

        let linhas: any | undefined = response.data.success?.linhas;
        setLinhas(linhas);

        let categorias: any | undefined = response.data.success?.categorias;
        setCategorias(categorias);

        let lancamentos: any | undefined = response.data.success?.lancamentos;
        setLancamentos(lancamentos);

        let produtos: any | undefined = response.data.success?.produtos;
        setProdutos(produtos);

        let posts: any | undefined = response.data.success?.posts;
        setPosts(posts);

        let sociais: any | undefined = response.data.success?.sociais;
        setRedesSociais(sociais);
      }
    });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      APICall("GET", `/cliente?token=${token}`).then(response => {
        if (response.data.status === 200) {
          setUsuario(response.data.success.cliente);
          let carrinho: string | null | Array<CarrinhoT> = localStorage.getItem("cart");
          if (carrinho) {
            carrinho = JSON.parse(carrinho);
            if (carrinho && typeof carrinho === "object") {
              carrinho = carrinho.filter((item: CarrinhoT) => {
                return item.cliente === response.data.success.cliente.id
              });
            }
          }
          setCarrinho(carrinho);
        }
      });
    }
  }, []);

  return (
    <Context.Provider value={value}>
      <HelmetProvider>
        {configuracoes ?
          <div className="App">
            <MultiLanguage />
            <Suspense fallback={<div className="suspense"></div>}>
              <RouterProvider router={router} />
            </Suspense>
          </div>
          : <></>
        }
      </HelmetProvider>
    </Context.Provider>
  );
}

function Main() {
  const { configuracoes, textos, usuario } = useContext(Context);
  const [withCache, setCache] = useState<boolean>(false);

  useEffect(() => {
    if (configuracoes && configuracoes.withCache === true) {
      setCache(true)
    } else {
      setCache(false);
    }
  }, [configuracoes]);

  return (
    <>
      <Metas />
      <ScrollToTop />
      <SEOHelper key={`seo_helper_updater_${withCache ? "cache" : "no_cache"}`} withCache={withCache} />
      <Header />
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/empresa`} element={<Empresa />} />
        <Route path={`/produtos`} element={<Produtos />} />
        <Route path={`/produto/:slug`} element={<Produto />} />
        <Route path={`/blog`} element={<Blog />} />
        <Route path={`/blog/:slug`} element={<Post />} />
        <Route path={`/contato`} element={<Contato />} />
        <Route path={`/trabalhe-conosco`} element={<Contato curriculo={true} />} />
        <Route path={`/downloads`} element={<Downloads />} />
        <Route path={`/obrigado/:slug`} element={<Obrigado />} />
        <Route path={`/minha-conta`} element={<Login />} />
        <Route path={`/recuperacao/:token`} element={<Login />} />
        <Route path={`/termos-de-uso`} element={<Generic title="Termos de Uso" content={textos.termos_de_uso} />} />
        <Route path={`/politica-de-privacidade`} element={<Generic title="Política de Privacidade" content={textos.politica_de_privacidade} />} />

        {usuario ? <>
          <Route path={`/minha-conta/2FA`} element={<TFA />} />
          <Route path={`/minha-conta/senha`} element={<Senha />} />
          <Route path={`/carrinho`} element={<Carrinho />} />
        </> : <></>
        }
      </Routes>
      <Footer />
      <Cookies />
      <GTManager />
      <Schema type="LocalBusiness" />
      <FloatWhatsapp />
    </>
  )
}

export default App;
