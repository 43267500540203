import axios from "axios";
const basename = "/sistema/api";

const APICall = (method:  "GET" | "POST" | "PUT" | "DELETE", route: string, data?: any, headers?: any) => {
    let response = axios({
        method: method,
        url: `${basename}${route}${method === "GET" ? data ? data : '' : ''}`,
        data: method !== "GET" ? data ? data : false : false,
        headers: headers ? headers : undefined
    }).then(res => {
        return res;
    });

    return response;
}

const getAPIRoute = () => {
    return basename;
}

export { getAPIRoute, basename };
export default APICall;