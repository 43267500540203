import { useEffect } from "react";
import "./MultiLanguage.css";

const createCookie = (name: string, value: string, days: number, domain: string) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; domain=" + domain + "; path=/";
}

const eraseCookie = (name: string, domain: string) => {
    createCookie(name, "", -1, domain);
}

const getCookie = (cookieName: string) => {
    let cookie: any = [];
    document.cookie.split(';').forEach(function (el: string) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    })
    return cookie[cookieName];
}

const setLanguage = (lang = "pt") => {
    eraseCookie("googtrans", `${window.location.hostname}`);
    eraseCookie("googtrans", `.${window.location.hostname}`);
    eraseCookie("googtrans", `.${window.location.hostname.replace("www.", "")}`);
    eraseCookie("googtrans", `${window.location.hostname.replace("www.", "")}`);
    eraseCookie("googtrans", "");
    createCookie("googtrans", `/pt/${lang}`, 1, `.${window.location.hostname}`);
}

const getLangFromUrl = () => {
    let getLangFromUrl: string = window.location.pathname;
    if (getLangFromUrl) {
        let lang: string = getLangFromUrl.substring(0, 4);
        if (lang[0] === "/" && lang[3] === "/") {
            return lang;
        }
    }

    return "/";
}

const MultiLanguage = () => {
    useEffect(() => {
        let currentLang: string | undefined = getCookie("googtrans");
        let getLangFromUrl: string = window.location.pathname;
        if (getLangFromUrl) {
            let lang: string = getLangFromUrl.substring(0, 4);
            if (lang[0] === "/" && lang[3] === "/") {
                if (!currentLang || currentLang.replace("/pt/", "") !== lang.replace("/", "")) {
                    setLanguage(lang.replace("/", ""));
                    window.location.reload();
                }
            } else {
                if (!currentLang || currentLang.replace("/pt/", "") !== "pt") {
                    setLanguage("pt");
                    window.location.reload();
                }
            }
        }

        let applied = false;
        const currentScripts = document.querySelectorAll("script");
        currentScripts.forEach(tag => {
            if (tag.src.includes("translate")) {
                applied = true;
            }
        });

        if (!applied) {
            const externalResource = document.createElement("script");
            externalResource.type = "text/javascript";
            externalResource.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";

            const translateScript = document.createElement("script");
            translateScript.type = "text/javascript";
            translateScript.innerHTML = "function googleTranslateElementInit() {new google.translate.TranslateElement({ pageLanguage: 'pt', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');}";

            document.body.appendChild(externalResource);
            document.body.appendChild(translateScript);
        }
    }, []);

    return <></>;
}

export default MultiLanguage;
export {
    setLanguage,
    createCookie,
    eraseCookie,
    getCookie,
    getLangFromUrl
}