import styles from "./Footer.module.css";
import Logo from "./Assets/logo.svg";
import FW2 from "./Assets/fw2.svg";
import SocialIcon, { SocialT } from "../../Components/DefaultPack/SocialIcon";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context";
import { uniqueId } from "lodash";

const Footer = () => {
    const { configuracoes, textos, posts, redesSociais } = useContext(Context);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.grid}>
                    <div className={styles.logo}>
                        <img src={Logo} alt="2A Cerâmica" />
                        <div className={styles.social}>
                            {redesSociais && redesSociais.length > 0 ? redesSociais.map((social: SocialT) => {
                                return <SocialIcon key={uniqueId("social_icon_")} {...social} />
                            }) : <></>}
                        </div>
                    </div>
                    <div className={styles.menu}>
                        <h4>Menu</h4>
                        <ul>
                            <li><Link to="/" title="Home" translate="no">Home</Link></li>
                            <li><Link to="/empresa" title="Empresa">Empresa</Link></li>
                            <li><Link to="/produtos" title="Produtos">Produtos</Link></li>
                            {posts && posts.length > 0 ? <li><Link to="/blog" title="Blog" translate="no">Blog</Link></li> : <></>}
                        </ul>
                    </div>
                    <div className={styles.menu}>
                        <h4>Links</h4>
                        <ul>
                            <li><a href={`${textos.catalogo}`} title="Catálogo" target="_blank" rel="noopener noreferrer">Catálogo</a></li>
                            <li><Link to="/downloads" title="Downloads" translate="no">Downloads</Link></li>
                            <li><Link to="/contato" title="Fale conosco">Fale conosco</Link></li>
                            <li><Link to="/trabalhe-conosco" title="Trabalhe conosco">Trabalhe conosco</Link></li>
                        </ul>
                    </div>
                    <div className={styles.menu}>
                        <h4>Termos</h4>
                        <ul>
                            <li><Link to="/termos-de-uso" title="Termos de uso">Termos de uso</Link></li>
                            <li><Link to="/politica-de-privacidade" title="Política de privacidade">Política de privacidade</Link></li>
                        </ul>
                    </div>
                    <div className={styles.contato}>
                        <h4>Contato</h4>
                        <p>{configuracoes.endereco} - {configuracoes.cidade} / {configuracoes.estado}</p>
                        <p><strong>Telefone: {configuracoes.telefone}</strong></p>
                        <p onClick={() => window.location.href=`https://wa.me/${configuracoes.whatsapp.replace(/\D/g, '')}`} className={styles.whatsapp}><strong>WhatsApp: {configuracoes.whatsapp}</strong></p>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.bottomBar}>
                    <p>{configuracoes.app_title} - {configuracoes.cnpj} - Todos os direitos reservados © {new Date().getUTCFullYear()}</p>
                    <a href="https://fw2propaganda.com.br/" target="_blank" rel="noopener noreferrer" title="Código por Gustavo Barbosa">Site desenvolvido por <img src={FW2} alt="FW2 Propaganda" /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;