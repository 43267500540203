import { useContext } from "react";
import Context from "../../../Context";
import { ProdutoT } from "../../Produto/interfaces";
import styles from "./Schema.module.css";
import { PostT } from "../../Post";

interface SchemaT {
    type: "Organization" | "LocalBusiness" | "Product" | "Article",
    itemProp?: string,
    produto?: ProdutoT,
    post?: PostT
}

const Schema = ({ type, produto, itemProp, post }: SchemaT) => {
    const { configuracoes } = useContext(Context);

    return (
        <div className={styles.wrapper}>
            {type === "Organization" || type === "LocalBusiness" ?
                <div itemProp={itemProp} itemScope itemType={`https://schema.org/${type}`}>
                    <span itemProp="name">{configuracoes.empresa}</span>
                    <span itemProp="image"></span>
                    <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                        <span itemProp="streetAddress">{configuracoes.endereco}</span>
                        <span itemProp="addressLocality">{configuracoes.cidade}</span>,<span itemProp="addressRegion">{configuracoes.estado}</span>
                        <span itemProp="postalCode">{configuracoes.cep}</span>
                    </div>
                    <span itemProp="telephone">{configuracoes.telefone}</span>
                    <a itemProp="url" href={configuracoes.url}>{configuracoes.url}</a>
                    <meta itemProp="openingHours" content={configuracoes.horario} />
                </div>
                : <></>
            }
            {type === "Product" && produto ?
                <div itemProp={itemProp} itemScope itemType={`https://schema.org/${type}`}>
                    <span itemProp="name">{produto.nome}</span>
                    <img itemProp="image" src={`${produto.obj_imagem.generic}.jpg`} alt={produto.nome} />
                    <div itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                        <meta itemProp="priceCurrency" content="BRL" />
                        <span itemProp="lowPrice" content={(produto.valor / 100).toFixed(2)}>{(produto.valor / 100).toFixed(2)}</span>
                        <span itemProp="highPrice" content={(produto.valor / 100).toFixed(2)}>{(produto.valor / 100).toFixed(2)}</span>
                        <span itemProp="offerCount" content={"30"}>{"30"}</span>
                    </div>
                    <Schema type="Organization" itemProp="manufacturer" />
                </div>
                : <></>
            }
            {type === "Article" && post ?
                <div itemProp={itemProp} itemScope itemType={`https://schema.org/${type}`}>
                    <span itemProp="name">{post.titulo}</span>
                    <img itemProp="image" src={`${post.imagem.generic}.jpg`} alt={post.titulo} />
                    <span itemProp="datePublished">{post.created_at}</span>
                    <Schema type="Organization" itemProp="author" />
                </div>
                : <></>
            }
        </div>
    )
}

export default Schema;